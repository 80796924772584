import React, { useState, useEffect } from "react";
import CertificateCard from "./Certificatecard/CertificateCard";
import usePdfView from "../hooks/use-pdf-view";
import {
  Modal,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyButton from "./Button/my-button";
import useAuth from "../hooks/use-auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const schema = z.object({
  documentName: z.string().min(1, "Document name is required"),
  documentExpire: z
    .date({
      required_error: "Document expire date is required",
      invalid_type_error: "Invalid date",
    })
    .nullable()
    .optional(),
  file: z
    .object({
      name: z.string(),
      size: z.number(),
    })
    .nullable()
    .optional(),
});

const PdfView = () => {
  const {
    pdfList,
    isViewLoading,
    uploadError,
    viewError,
    uploadPdf,
    updatePdf,
    getPdf,
    fetchPdfList,
    deletePdf,
  } = usePdfView();
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [searchOpen, setSearchOpen] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });

  const { getUserDetails } = useAuth();
  const { role } = getUserDetails();

  useEffect(() => {
    fetchPdfList(searchKeyword);
  }, [searchKeyword]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleModalOpen = (id = null) => {
    setIsModalOpen(true);
    setSelectedDocumentId(id);
    if (id) {
      const selectedPdf = pdfList.find((pdf) => pdf.id === id);
      if (selectedPdf) {
        setValue("documentName", selectedPdf.documentName);
        setValue("documentExpire", new Date(selectedPdf.documentExpire));
      }
    } else {
      reset();
      setValue("documentName", "");
      setValue("documentExpire", "");
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedDocumentId(null);
    setFile(null);
    reset();
  };

  const onSubmit = async (data) => {
    try {
      if (selectedDocumentId === null) {
        await uploadPdf(file, data.documentName, data.documentExpire);
      } else {
        await updatePdf(selectedDocumentId, file, data.documentName, data.documentExpire);
      }
      toast.success("Document saved successfully");
      fetchPdfList();
      handleModalClose();
    } catch (error) {
      toast.error("Failed to save document");
      console.error("Failed to save document:", error);
    }
  };

  const handleView = async (id) => {
    try {
      const pdf = await getPdf(id);
      setPdfData(pdf.data);
    } catch (error) {
      console.error("Failed to fetch PDF:", error);
    }
  };

  const handleEdit = (id) => {
    handleModalOpen(id);
  };

  const handleDelete = async (id) => {
    try {
      await deletePdf(id);
      toast.success("Document deleted successfully");
      fetchPdfList();
    } catch (error) {
      toast.error("Failed to delete document");
      console.error("Failed to delete document:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const toggleSearch = () => {
    setSearchKeyword("");
    setSearchOpen(searchOpen);
  };

  return (
    <div>
      <ToastContainer theme="colored" autoClose={2000} stacked closeOnClick />

      <div className="flex justify-end">
        {/* Search Icon Placeholder */}
      </div>

      {searchOpen && (
        <div className="flex justify-end gap-5 mr-8 mt-2 mb-3 ml-6">
          <input
            type="text"
            placeholder="What are you looking for?"
            value={searchKeyword}
            onChange={handleSearchChange}
            className="border border-gray-300 rounded-md px-2 py-1 w-full h-14"
          />
        </div>
      )}

      {role !== 5 && role !== 4 && (
        <div className="flex justify-start mb-8 ml-6">
          <MyButton onClick={() => handleModalOpen()} disabled={isViewLoading}>
            Add Document
          </MyButton>
          {uploadError && <p className="text-red-500">Error: {uploadError}</p>}
        </div>
      )}

      {isViewLoading ? (
        <div className="flex justify-center items-center h-40">
          <CircularProgress />
        </div>
      ) : pdfList && pdfList.length > 0 ? (
        <div className="flex flex-wrap justify-center items-center gap-20">
          {pdfList.map((pdfDoc) => (
            <CertificateCard
              key={pdfDoc.id}
              logoSrc="https://via.placeholder.com/100"
              onClickView={() => handleView(pdfDoc.id)}
              documentName={pdfDoc.documentName}
              documentExpire={pdfDoc.documentExpire}
              onEdit={() => handleEdit(pdfDoc.id)}
              onDelete={() => handleDelete(pdfDoc.id)}
              showActions={true}
            />
          ))}
        </div>
      ) : (
        <p>No documents found.</p>
      )}

      <Modal open={isModalOpen} onClose={handleModalClose} className="flex justify-center items-center">
        <div className="bg-white p-8 rounded-lg w-96">
          <h2 className="text-2xl font-bold mb-4">
            {selectedDocumentId === null ? "Add Document" : "Edit Document"}
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <TextField
              label="Document Name"
              fullWidth
              error={!!errors.documentName}
              {...register("documentName")}
              helperText={errors.documentName?.message}
            />

            <div>
              <label className="block mb-1">Document Expire Date</label>
              <DatePicker
                selected={watch("documentExpire")}
                onChange={(date) => setValue("documentExpire", date)}
                dateFormat="dd/MM/yyyy"
                className={`border border-gray-300 rounded px-3 py-2 w-full ${
                  errors.documentExpire ? "border-red-500" : ""
                }`}
              />
              {errors.documentExpire && <p className="text-red-500 text-sm mt-1">{errors.documentExpire.message}</p>}
            </div>

            <TextField type="file" fullWidth onChange={handleFileChange} error={!!errors.file} />

            <div className="flex justify-center space-x-4">
              <MyButton type="submit">Submit</MyButton>
              <MyButton type="reset" onClick={handleModalClose}>
                Cancel
              </MyButton>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default PdfView;
