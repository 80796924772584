import DocumentView from "../components/document-expire";
import Layout from "../components/Layout";


function DocumentViewPage() {
  return (
   <>
   <div>
    <Layout>
        <DocumentView/>
    </Layout>
   </div>
   </>
  );
}
export default DocumentViewPage;
// <PDFViewer>
//         <MyDocument />
//       </PDFViewer>