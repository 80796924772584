import React, { useState, useEffect } from "react";
import CertificateCard from "./Certificatecard/CertificateCard";
import usePdfView from "../hooks/use-pdf-view";
import {
  Modal,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyButton from "./Button/my-button";
import useAuth from "../hooks/use-auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Validation Schema
const schema = z.object({
  documentName: z.string().min(1, "Document name is required"),
  documentExpire: z.date().nullable().optional(),
  file: z.object({ name: z.string(), size: z.number() }).nullable().optional(),
});

const DocumentView = () => {
  const {
    pdfList,
    isViewLoading,
    uploadPdf,
    updatePdf,
    getPdf,
    fetchPdfList,
    // deletePdf,
  } = usePdfView();

  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({ resolver: zodResolver(schema), mode: "onBlur" });

  const { getUserDetails } = useAuth();
  const { role } = getUserDetails();

  // Fetch PDFs on searchKeyword change
  useEffect(() => {
    fetchPdfList(searchKeyword);
  }, [searchKeyword]);

  const handleFileChange = (event) => setFile(event.target.files[0]);

  const handleModalOpen = (id = null) => {
    setIsModalOpen(true);
    setSelectedDocumentId(id);
    if (id) {
      const selectedPdf = pdfList.find((pdf) => pdf.id === id);
      if (selectedPdf) {
        setValue("documentName", selectedPdf.documentName);
        setValue("documentExpire", new Date(selectedPdf.documentExpire));
      }
    } else {
      reset();
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedDocumentId(null);
    setFile(null);
    reset();
  };

  const onSubmit = async (data) => {
    try {
      if (selectedDocumentId === null) {
        await uploadPdf(file, data.documentName, data.documentExpire);
      } else {
        await updatePdf(selectedDocumentId, file, data.documentName, data.documentExpire);
      }
      toast.success("Document saved successfully");
      fetchPdfList();
      handleModalClose();
    } catch (error) {
      toast.error("Failed to save document");
    }
  };

  const handleView = async (id) => {
    try {
      setLoadingPdf(true);
      const pdf = await getPdf(id);
      setPdfData(pdf.data);
    } catch (error) {
      console.error("Failed to fetch PDF:", error);
    } finally {
      setLoadingPdf(false);
    }
  };

  // const handleEdit = (id) => handleModalOpen(id);

  // const handleDelete = async (id) => {
  //   try {
  //     await deletePdf(id);
  //     toast.success("Document deleted successfully");
  //     fetchPdfList();
  //   } catch (error) {
  //     toast.error("Failed to delete document");
  //   }
  // };

  const handleSearchChange = (event) => setSearchKeyword(event.target.value);
  const currentDate = new Date();
  const filteredPdfList = pdfList.filter((pdfDoc) => !pdfDoc.documentExpire || new Date(pdfDoc.documentExpire) > currentDate);

  return (
    <div>
      <ToastContainer theme="colored" autoClose={2000} stacked closeOnClick />

      {/* Loader when fetching documents */}
      {isViewLoading ? (
        <div className="flex justify-center items-center min-h-screen">
          <div className="loader"></div>
        </div>
      ) : filteredPdfList.length > 0 ? (
        <div className="flex flex-wrap justify-center items-center gap-20">
          {filteredPdfList.map((pdfDoc) => (
            <CertificateCard
              key={pdfDoc.id}
              logoSrc="https://via.placeholder.com/100"
              onClickView={() => handleView(pdfDoc.id)}
              documentName={pdfDoc.documentName}
              documentExpire={pdfDoc.documentExpire}
              showActions={false}
              // onEdit={() => handleEdit(pdfDoc.id)}
              // onDelete={() => handleDelete(pdfDoc.id)}
            />
          ))}
        </div>
      ) : (
        <p>No documents found.</p>
      )}

      {/* Modal for Adding/Editing Document */}
      <Modal open={isModalOpen} onClose={handleModalClose} className="flex justify-center items-center">
        <div className="bg-white p-8 rounded-lg w-96">
          <h2 className="text-2xl font-bold mb-4">
            {selectedDocumentId === null ? "Add Document" : "Edit Document"}
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <TextField
              label="Document Name"
              fullWidth
              error={!!errors.documentName}
              {...register("documentName")}
              helperText={errors.documentName?.message}
            />

            <div>
              <label className="block mb-1">Document Expire Date</label>
              <DatePicker
                selected={watch("documentExpire")}
                onChange={(date) => setValue("documentExpire", date)}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                showMonthDropdown
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
              {errors.documentExpire && <p className="text-red-500 text-sm mt-1">{errors.documentExpire.message}</p>}
            </div>

            <TextField type="file" fullWidth onChange={handleFileChange} error={!!errors.file} />

            <div className="flex justify-center space-x-4">
              <MyButton type="submit">Submit</MyButton>
              <MyButton type="reset" onClick={handleModalClose}>Cancel</MyButton>
            </div>
          </form>
        </div>
      </Modal>

      {/* PDF View Modal with Loader */}
      <Modal open={!!pdfData} onClose={() => setPdfData(null)} className="flex justify-center items-center">
        <Box className="bg-white p-8 rounded-lg w-full max-w-3xl">
          <h2 className="text-2xl font-bold mb-4">View Document</h2>
          {loadingPdf ? (
            <div className="flex justify-center items-center">
              <div className="loader"></div>
            </div>
          ) : (
            <object data={`data:application/pdf;base64,${pdfData}`} type="application/pdf" width="100%" height="600px">
              <p>Unable to display PDF. <a href={`data:application/pdf;base64,${pdfData}`}>Download PDF</a></p>
            </object>
          )}
        </Box>
      </Modal>

      {/* CSS Loader */}
      <style>
        {`
          .loader {
            border: 4px solid #f3f3f3;
            border-top: 4px solid #3498db;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default DocumentView;
