import React, { useState, useEffect } from "react";
import useEmployeeDetails from "../hooks/useEmployeeDetails";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button,
  TextField,
  Modal,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import useLeaveTypes from "../hooks/use-leave-type";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyButton from "./Button/my-button";
import useAuth from "../hooks/use-auth";
import DatePickerModal from "./date-picker-modal";
import useAddEmployeeImage from "../hooks/use-add-employee-image";
import PrintIcon from "@mui/icons-material/Print";
const schema = z.object({
  leaveType: z.number().min(1, "Leave type is required"),
  days: z.string().refine((value) => /^\d+$/.test(value), {
    message: "Please enter a valid number",
  }),
  reason: z
    .string()
    .min(1, "Reason is required")
    .max(100, "Reason must be less than 100 characters"),
  transactionType: z
    .number()
    .min(0, "Leave status is required")
    .max(1, "Invalid leave status value"),
});

const EmployeeDetails = () => {
  const location = useLocation();
  const { employeeId } = location.state;
  const { employeeDetails, isLoading, error, refetch } =
    useEmployeeDetails(employeeId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [selectedTransactionType, setSelectedTransactionType] = useState("");
  const [isModalOpenEligibility, setIsModalOpenEligibility] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [key, setKey] = useState(0);
  const [imageSize, setImageSize] = useState(0);
  const { uploadImage, viewImage } = useAddEmployeeImage();
  const { getUserDetails } = useAuth();
  const { role } = getUserDetails();
  const {
    leaveTypes,
    isLoading: leaveTypesLoading,
    error: leaveTypesError,
    addLeave,
    leaveTypesHr,
  } = useLeaveTypes();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });
  useEffect(() => {
    const fetchUserImage = async () => {
      if (employeeDetails && employeeDetails.id) {
        try {
          const imageResponse = await viewImage(employeeDetails.id);
          console.log("User Image:", imageResponse.data.size);
          setImageSize(imageResponse.data.size);
          const imageUrl = URL.createObjectURL(imageResponse.data);
          setImageUrl(imageUrl);
          console.log(imageUrl);
        } catch (error) {
          console.error("Error fetching User image:", error);
        }
      }
    };
    fetchUserImage();
  }, [employeeDetails, viewImage, key]);
  useEffect(() => {
    // Reset form when modal opens
    if (isModalOpen) {
      reset();
    }
  }, [isModalOpen, reset]);

  if (isLoading || leaveTypesLoading) {
    return <CircularProgress />;
  }

  if (error || leaveTypesError) {
    return <div>Error: {error || leaveTypesError}</div>;
  }

  if (!employeeDetails) {
    return <div>No employee details found.</div>;
  }
  const handleLeaveTypeChange = (event) => {
    const value = event.target.value;
    setSelectedLeaveType(value);
    // Set the value of leaveType for validation
    setValue("leaveType", value);
  };
  const handleLeaveStatusChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setSelectedTransactionType(value);
    // Set the value of leaveStatus for validation
    setValue("transactionType", value);
  };
  const handleAddLeave = () => {
    setIsModalOpen(true);
  };
  const handleUpdateEligiblity = () => {
    console.log("------");
    setIsModalOpenEligibility(true);
  };
  const handleCloseModalEligibility = () => {
    setIsModalOpenEligibility(false);
    refetch();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedLeaveType("");
    reset();
  };
  const handleFileChange = (e) => {
    // Handle file input change logic
    setSelectedFile(e.target.files[0]);
    console.log("File selected", e.target.files[0]);
  };

  const onImageUpload = async (data) => {
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }

    try {
      // Implement the image upload logic here
      await uploadImage(employeeDetails.id, selectedFile);
      console.log("Image file:", selectedFile);
      toast.success("Image uploaded successfully");
      setIsImageModalOpen(false);
      setKey((prevKey) => prevKey + 1);
    } catch (error) {
      toast.error("Failed to upload image:", error);
      console.error("Failed to upload image:", error);
    }
  };

  const handleImageUpload = () => {
    setIsImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setIsImageModalOpen(false);
  };

  const onSubmit = async (data, e) => {
    try {
      const leaveData = {
        daysUpdated: parseInt(data.days), // Assuming 'days' is a string, parse it to an integer
        user: employeeDetails.id, // Assuming this value is fixed or determined from somewhere else
        reason: data.reason,
        leaveType: selectedLeaveType,
        transactionType: selectedTransactionType,
      };
      console.log(leaveData);
      await addLeave(leaveData); // Call the addLeave function from the useLeaveTypes hook
      toast.success("Leave added successfully");
      e.target.reset();
    } catch (error) {
      toast.error("Failed to submit leave:", error);
      console.error("Failed to submit leave:", error);
    }
    setIsModalOpen(false);
  };
  const handleRejoinVacation = () => {
    toast.success("Leave added successfully");
  };
  const handlePrint = async () => {
    window.print();
  };
  return (
    <div className="flex flex-col md:flex-row bg-gray-100 mt-5">
      <ToastContainer theme="colored" autoClose={2000} stacked closeOnClick />
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg p-6 md:p-12 w-full">
        <div className="w-full md:w-1/3 flex flex-col justify-center items-center mb-6 md:mb-0">
          <img
            src={imageUrl || "/default-profile.png"}
            alt="Profile"
            className="w-64 h-64 rounded-full object-cover mb-6"
          />
          <div className="flex justify-center items-center mt-4">
            <MyButton type="button" onClick={handleImageUpload}>
              Upload Image
            </MyButton>
          </div>
        </div>
        <div className="w-full md:w-2/3 md:pl-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-bold">
              {employeeDetails.name}{" "}
              <span
                className={`${
                  employeeDetails.status === 1
                    ? "bg-green-500 text-white"
                    : "bg-red-500 text-white"
                } font-normal text-sm rounded-md p-1`}
              >
                {employeeDetails.status === 1 ? "On Premises" : "Vacation"}
              </span>
              <button onClick={handlePrint} className="ml-3 print-button">
                <PrintIcon />
              </button>
            </h2>
          </div>
          <p className="text-gray-600 mb-4">{employeeDetails.role}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">QID:</span>
              <span>{employeeDetails.qid}</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Employee ID:</span>
              <span>{employeeDetails.employeeId}</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Job Title:</span>
              <span>{employeeDetails.jobTitle}</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Nationality:</span>
              <span>{employeeDetails.nationality}</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Gender:</span>
              <span>{employeeDetails.gender === 0 ? "Male" : "Female"}</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Contract Period:</span>
              <span>{employeeDetails.contractPeriod} months</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Passport No:</span>
              <span>{employeeDetails.passport}</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Qualification:</span>
              <span>{employeeDetails.qualification}</span>
            </div>

            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Total Experience:</span>
              <span>{employeeDetails.totalExperience} years</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Joining Date:</span>
              <span>
                {new Date(employeeDetails.joiningDate).toLocaleDateString()}
              </span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">QID Expire:</span>
              <span>
                {employeeDetails.qidExpire.join("-")},{" "}
                <span className="font-extrabold text-red-800">
                  {employeeDetails.noOfDaysQidExpire}
                </span>{" "}
                days left
              </span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Passport Expire:</span>
              <span>
                {employeeDetails.passportExpire.join("-")},{" "}
                <span className="font-extrabold text-red-800">
                  {employeeDetails.noOfDaysPassportExpire}
                </span>{" "}
                days left
              </span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">License:</span>
              {employeeDetails.license ? (
                <span> {employeeDetails.license}</span>
              ) : (
                "N/A"
              )}
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">License Expire:</span>
              <span>
                {employeeDetails.licenseExpire ? (
                  <>
                    {employeeDetails.licenseExpire.join("-")},{" "}
                    <span className="font-extrabold text-red-800">
                      {employeeDetails.noOfDaysLicenseExpire}
                    </span>{" "}
                    days left
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="text-gray-700 font-bold">Eligible Date:</span>
              <span>
                {employeeDetails.lastEligibilityDate ? (
                  <>
                    {new Date(
                      employeeDetails.lastEligibilityDate
                    ).toLocaleDateString()}
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>
          <div className="flex justify-start space-x-4">
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor:
                  employeeDetails.status === 1 ? "orange" : "green",
              }}
              onClick={handleRejoinVacation}
            >
              {employeeDetails.status === 1 ? "Vacation" : "Re-joining"}
            </Button>
            <Button
              disabled={role !== 1 && role !== 4 && role !== 2}
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor:
                  employeeDetails.status === 1 ? "orange" : "green",
              }}
              onClick={handleAddLeave}
            >
              Add Leave
            </Button>
            <Button
              disabled={role !== 1 && role !== 4 && role !== 2}
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor:
                  employeeDetails.status === 1 ? "orange" : "green",
              }}
              onClick={handleUpdateEligiblity}
            >
              Update Last Eligiblity
            </Button>
          </div>
        </div>
        
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="add-leave-modal-title"
        aria-describedby="add-leave-modal-description"
        className="flex justify-center items-center"
      >
        <div className="bg-white p-8 rounded-lg w-96">
          <h2 id="add-leave-modal-title" className="text-2xl font-bold mb-4">
            Add Leave
          </h2>
          <p className="text-gray-600 mb-4">
            Employee: {employeeDetails.name} ({employeeDetails.role})
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <FormControl fullWidth error={!!errors.leaveType}>
              <InputLabel id="leave-type-label">Leave Type</InputLabel>
              <Select
                labelId="leave-type-label"
                id="leave-type"
                value={selectedLeaveType}
                onChange={handleLeaveTypeChange}
                label="Leave Type"
              >
                {leaveTypesHr && leaveTypesHr.length > 0 ? (
                  leaveTypesHr
                    .filter((leaveType) => {
                      // Check role and filter leave types accordingly
                      if (role === 4) {
                        return (
                          leaveType.id === 2 ||
                          leaveType.id === 3 ||
                          leaveType.id === 5
                        );
                      }
                      // If role is not 4, display all leave types
                      return true;
                    })
                    .map((leaveType) => (
                      <MenuItem key={leaveType.id} value={leaveType.id}>
                        {leaveType.name}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem value="">
                    <em>No leave types available</em>
                  </MenuItem>
                )}
              </Select>
              {errors.leaveType && (
                <p className="text-red-500">{errors.leaveType.message}</p>
              )}
            </FormControl>
            <FormControl fullWidth variant="outlined" className="mb-4">
              <InputLabel id="leave-status-label">Leave Status</InputLabel>
              <Select
                {...register("transactionType")}
                labelId="leave-status-label"
                id="transaction-type"
                value={selectedTransactionType}
                onChange={handleLeaveStatusChange}
                label="Leave Status"
              >
                <MenuItem value={0}>Add</MenuItem>
                <MenuItem value={1}>Delete</MenuItem>
              </Select>
              {errors.transactionType && (
                <p className="text-red-500">{errors.transactionType.message}</p>
              )}
            </FormControl>
            <TextField
              id="days"
              label="Days"
              type="number" // Change type to "number"
              fullWidth
              error={!!errors.days}
              {...register("days", {
                required: "Number of days is required", // Add required validation
              })}
              inputProps={{
                inputMode: "numeric", // Set inputMode to "numeric" to show numeric keypad on mobile devices
              }}
              helperText={errors.days ? errors.days.message : null}
            />
            <TextField
              id="reason"
              label="Reason"
              fullWidth
              error={!!errors.reason}
              {...register("reason")}
              helperText={errors.reason ? errors.reason.message : null}
            />
            <div className="flex justify-center space-x-4">
              <MyButton type="submit">Submit</MyButton>

              <MyButton type="reset" onClick={handleModalClose}>
                Cancel
              </MyButton>
            </div>
          </form>
        </div>
      </Modal>
      {employeeDetails && (
        <DatePickerModal
          isOpen={isModalOpenEligibility}
          handleClose={handleCloseModalEligibility}
          employeeId={employeeDetails.id}
        />
      )}
      <Modal
        open={isImageModalOpen}
        onClose={handleImageModalClose}
        className="flex justify-center items-center"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-bold mb-4">Upload Profile Picture</h2>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="mb-4"
          />
          <div className="flex justify-end">
            <MyButton type="button" onClick={onImageUpload}>
              Upload
            </MyButton>
            <MyButton type="reset" onClick={handleImageModalClose}>
              Cancel
            </MyButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeDetails;
